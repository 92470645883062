<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 28 32"
  >
    <path
      fill="currentColor"
      d="M11 2h6a1 1 0 0 1 1 1v2h-8V3a1 1 0 0 1 1-1Zm9 3V3a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v2H1a1 1 0 0 0 0 2h1.08l1.7 21.32A4 4 0 0 0 7.77 32h12.46a4 4 0 0 0 3.99-3.68L25.92 7H27a1 1 0 1 0 0-2h-7Zm3.92 2-1.7 21.16A2 2 0 0 1 20.23 30H7.77a2 2 0 0 1-2-1.84L4.09 7h19.84ZM8.94 9a1 1 0 0 1 1.06.94l1 17a1 1 0 0 1-2 .12l-1-17A1 1 0 0 1 8.94 9Zm10.12 0a1 1 0 0 1 .94 1.06l-1 17a1 1 0 1 1-2-.12l1-17A1 1 0 0 1 19.06 9ZM14 9a1 1 0 0 1 1 1v17a1 1 0 0 1-2 0V10a1 1 0 0 1 1-1Z"
    />
  </svg>
</template>
